<template>
	<div class="container-fluid child-view">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>Phone Records</h1>
				<p class="lead text-muted">
					Recent call data pertaining to you or your subordinates.
				</p>
			</div>
			<div class="col-auto mr-auto">
				<a
					href=""
					class="btn btn-lg btn-outline-primary"
					@click.prevent="init()"
					><i class="material-icons">refresh</i>Refresh
				</a>
			</div>
		</div>

		<pre v-if="vm_inbox">vm_inbox: {{ vm_inbox[0] }}</pre>

		<section v-if="vm_inbox && vm_inbox.length">
			<div class="row">
				<div class="col pb-3"><h2>Voice Mail</h2></div>
			</div>

			<div class="table-responsive">
				<table class="table table-borderless">
					<thead>
						<tr>
							<th scope="col">Id</th>
							<th scope="col">Response Time</th>
							<th scope="col">Responder</th>
							<th scope="col">Facility</th>
							<th scope="col" class="text-right pr-3">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr
							:class="{
								'table-info': row.highlight,
								'table-warning': !row.highlight && !row.responded
							}"
							style="transition-duration: 1s;"
							v-for="row in vm_inbox"
							:key="'vm-inbox-row-' + row.phone_call_id"
						>
							<td>{{ row.phone_call_id }}</td>
							<td>
								<div v-if="row.responded">
									{{ formatDate(row.responded) }}
									<br />
									{{ formatTime(row.responded) }}
								</div>
							</td>
							<td>
								{{ row.responded_first_name }} {{ row.responded_last_name }}
							</td>
							<td>{{ row.origin_facil_name }}</td>
							<td class="d-flex justify-content-end">
								<div
									data-toggle="buttons"
									class="btn-group-toggle text-right ml-1 d-flex"
								>
									<play-stop-audio
										v-if="row.recording_url"
										:playing="row.playing ? row.playing : false"
										:audio-file="row.recording_url"
									/>

									<label
										v-if="row.responded"
										disabled
										class="btn btn-sm- text-light blue-grey lighten-1"
										><input
											type="checkbox"
											id="filterUsers[]"
											class=""
											value="1"
											disabled
										/><i class="material-icons -lime-text">check</i
										>Responded</label
									><a
										href=""
										@click.prevent="markAsDone(row)"
										v-else
										class="btn -btn-loading btn-primary"
										:class="{ 'btn-loading': row.loading }"
										:disabled="row.loading"
										><i v-show="!row.loading" class="material-icons"
											>crop_square</i
										>Mark Response
									</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!---->
		</section>

		<h3 v-else>No voicemail at this time.</h3>

		<section v-if="call_log && call_log.length">
			<div class="row pt-5">
				<div class="col pb-3"><h2>Call Log</h2></div>
			</div>
			<div class="table-responsive">
				<table class="table table-borderless">
					<thead>
						<tr>
							<th scope="col">Id</th>
							<th scope="col">Call Time</th>
							<th scope="col">Routed To</th>
							<th scope="col">Facility</th>
							<th scope="col">Status</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="row in call_log"
							:key="'call-log-row-' + row.id"
							:class="{ 'table-danger': row.status === 'error' }"
						>
							<td>{{ row.id }}</td>
							<td>
								{{ formatDate(row.call_time) }}
								<br />
								{{ formatTime(row.call_time) }}
							</td>

							<!--						<td>{{ row.origin_slug }}</td>-->
							<td>
								{{ row.routed_to_first_name }} {{ row.routed_to_last_name }}
								<br />{{ row.routed_to_num }}
							</td>
							<td>{{ row.origin_facil_name }}</td>
<!--							<td style="text-transform: capitalize;">-->
<!--								<div class="d-flex align-items_center">-->
<!--									<i-->
<!--										v-if="row.status === 'error'"-->
<!--										class="large material-icons mr-2 text-danger"-->
<!--										style="font-size: 22px; position: absolute; left: 0; top: 0;"-->
<!--										>error</i-->
<!--									>-->
<!--									<i-->
<!--										v-else-->
<!--										class="large material-icons mr-2 text-success"-->
<!--										style="font-size: 22px; position: absolute; left: 0; top: 0;"-->
<!--										>check_circle</i>-->
<!--										<span class="status-text">{{ row.status }}</span>-->
<!--								</div>-->
<!--							</td>-->
							<td style="text-transform: capitalize; position: relative;">
								<div class="status-container">
									<i
											v-if="row.status === 'error'"
											class="material-icons text-danger"
											style="font-size: 22px; position: absolute; left: 0;"
									>error</i>
									<i
											v-else
											class="material-icons text-success"
											style="font-size: 22px; position: absolute; left: 0;"
									>check_circle</i>
									<span class="status-text">{{ row.status }}</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!---->
		</section>

		<h3 v-else class="pt-3">No call logs at this time.</h3>

		<pre>call_log: {{ call_log[0] }}</pre>
	</div>
</template>

<script>
import moment from "moment";

import { mapActions } from "vuex";
import PlayStopAudio from "@/components/PlayStopAudio";

export default {
	data() {
		return {
			vm_inbox: [
				// {
				// 	phone_call_id: null,
				// 	responded: null,
				// 	responded_by: null,
				// 	recording_url: null,
				// 	deleted: null
				// }
			],
			call_log: [
				// {
				// 	routed_to: null, // id if single route call // call was rung without prompt
				// 	id: "1",
				// 	call_time: "2019-11-25 15:52:57",
				// 	incoming_sid: null,
				// 	origin_facil: "6",
				// 	origin_slug: "d5xoDBvYQk",
				// 	routed_to_num: "8337866948",
				// 	status: "error"
				// }
			]
		};
	},

	components: {
		PlayStopAudio
	},

	name: "PhoneLogs",
	methods: {
		...mapActions([
			"ajax",
			"globalAlert"
			//'setDestination'
		]),
		formatDate(dateTime) {
			return moment(dateTime).format("dddd MMM Do");
		},
		formatTime(dateTime) {
			return moment(dateTime).format("h:mm a");
		},
		init() {
			this.ajax({
				url: "/log/phone_logs",
				data: {},
				success: json => {
					console.log("phone_logs", json);
					if (json.call_log) {
						this.call_log = json.call_log;
					}
					if (json.vm_inbox) {
						this.vm_inbox = json.vm_inbox;
					}
				}
			});
		},
		markAsDone(row) {
			this.$set(row, "loading", true);
			this.ajax({
				url: "/log/mark_vm_responded",
				data: { phone_call_id: row.phone_call_id },
				success: json => {
					console.log("phone_logs", json);
					this.$set(row, "responded_first_name", json.responded_first_name);
					this.$set(row, "responded_last_name", json.responded_last_name);
					this.$set(row, "highlight", true);
					this.$set(row, "loading", false);
					//const now = this.$moment().format("YYYY-MM-DD hh:mm");
					this.$set(row, "responded", json.responded);

					setTimeout(() => {
						this.$set(row, "highlight", false);
					}, 1001);
				}
			});
		}
	},
	mounted() {
		this.init();
	}
};
</script>

<style lang="scss">
.dspinner {
	width: 40px;
	height: 40px;

	position: relative;
	margin: 1px;
}

.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #78909c;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.status-container {
  position: relative;
  width: 100px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
}

.status-text {
  margin-left: 10px; /* Adjust the margin as needed */
	margin-bottom: 8px;
}
</style>
