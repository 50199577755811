<template>
	<section class="d-flex">
		<div class="dspinner" v-if="playing">
			<div class="double-bounce1"></div>
			<div class="double-bounce2"></div>
		</div>

		<a
			v-if="!playing"
			href=""
			@click.prevent="playAudio()"
			class="btn  btn-success"
			><i class="material-icons -lime-text">play_circle_filled</i>Play</a
		>
		<a v-else href="" @click.prevent="stopAudio()" class="btn ml-1 btn-danger"
			><i class="material-icons -lime-text">stop</i>Stop</a
		>
	</section>
</template>
<script>
export default {
	props: ["audioFile", "playing"],
	name: "PlayStopAudio",
	data() {
		return {
			audio: null
		};
	},
	methods: {
		playAudio() {
			this.playing = true;
			this.audio.play();
		},
		stopAudio() {
			this.playing = false;
			this.audio.currentTime = 0;
			this.audio.pause();
		}
	},
	mounted() {
		this.audio = new Audio(this.audioFile);
	}
};
</script>

<style scoped></style>
